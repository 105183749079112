import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentification.service';
import { filter, map, take } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserAppLoadNavigationService } from '@app/services/user-app-load-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard implements CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private userAppLoadNavigation: UserAppLoadNavigationService
  ) {}

  goUserDevices = false;
  userHasDevices;
  userHasPatients;

  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        if (isAuthenticated) {
          var userRole = localStorage.getItem('role');
          var userId = localStorage.getItem('user_id');
          if (userRole == 'ADMIN') {
            this.router.navigateByUrl('/' + userId + '/home-bo', {
              replaceUrl: true,
            });
          } else if (userRole == 'USER') {
            this.doesUserHasDevicesOrPatients(userId);
          }
        } else {
          // Simply allow access to the login
          return true;
        }
      })
    );
  }

  countPendingContacts(patientContacts) {
    var count = 0;
    patientContacts.forEach((patientContact) => {
      if (patientContact.invitationState == 'PENDING') {
        count++;
      }
    });
    return count;
  }

  doesUserHasDevicesOrPatients(userId) {
    let pendingCount = 0;
    this.userService.getUserOwnedDevices(userId).subscribe(
      (devices) => {
          this.userService.getUserPatients(userId).subscribe(
            (userHasPatients) => {
              pendingCount = this.countPendingContacts(userHasPatients);
              this.userAppLoadNavigation.redirectUserOnAppLoad(pendingCount, userId, devices, userHasPatients);
            },
            (err) => {
              this.presentAlert(this.translateService.instant("error"), '', this.translateService.instant(`backend.${err.error.detail}`));
            }
          );
      },
      (err) => {
        this.presentAlert(this.translateService.instant("error"), '', this.translateService.instant(`backend.${err.error.detail}`));
      }
    );
  }

  async presentAlert(toheader: string, tosubheader: string, tomessage: string) {
    const alert = await this.alertCtrl.create({
      header: toheader,
      subHeader: tosubheader,
      message: tomessage,
      buttons: ['OK'],
    });
    await alert.present();
  }
}
