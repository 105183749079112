//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { catchError } from 'rxjs/operators';

type HttpMethod =
  | 'get'
  | 'post'
  | 'put'
  | 'patch'
  | 'head'
  | 'delete'
  | 'upload'
  | 'download';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private nativeHttp: HTTP, private platform: Platform, private alertCtrl: AlertController) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.platform.is('hybrid')) {
      return from(this.handle(request, next));
    }
    return from(this.handleNativeRequest(request));
  }

  private async handle(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(this.updateHeaders(request)).pipe(
      catchError(error => {
        if (error.status === 0) {
          error.error.detail = "SERVER_DOWN"
        }
        return throwError(error)
      })
    ).toPromise();
  }

  private async handleNativeRequest(
    request: HttpRequest<any>
  ): Promise<HttpEvent<any>> {
    request = this.updateHeaders(request);

    const headerKeys = request.headers.keys();
    const headers = {};

    headerKeys.forEach((key) => {
      headers[key] = request.headers.get(key);
    });

    try {
      const method = <HttpMethod>request.method.toLowerCase();
      const nativeHttpResponse = await this.nativeHttp.sendRequest(
        request.url,
        {
          method: method,
          data: request.body,
          headers: headers,
          serializer: 'json',
        }
      );

      let body;
      try {
        body = JSON.parse(nativeHttpResponse.data);
      } catch (error) {
        body = { response: nativeHttpResponse.data };
      }

      const response = new HttpResponse({
        body: body,
        status: nativeHttpResponse.status,
        headers: new HttpHeaders(nativeHttpResponse.headers),
        url: nativeHttpResponse.url,
      });
      return Promise.resolve(response);
    } catch (error) {
      if (!error.status) {
        return Promise.reject(error);
      }

      const response = new HttpResponse({
        body: JSON.parse(error.error),
        status: error.status,
        headers: error.headers,
        url: error.url,
      });
      return Promise.reject(response);
    }
  }

  private updateHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const token = localStorage.getItem('token');
    let headers = {'Accept': 'application/json'};

    //Authentication by setting header with token value
    if (token && token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    if (!request.headers.has('Content-Type')) {
      headers['Content-Type'] = 'application/json';
    }
    return request.clone({setHeaders: headers});
  }
}
