import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from, defer } from 'rxjs';
import { environment } from '@environments/environment';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = '';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    }),
  };

  constructor(
    private http: HttpClient,
    private native: HTTP,
    private platform: Platform
  ) {
    this.loadToken();
  }

  async loadToken() {
    const token = await localStorage.getItem('token');
    if (token && token) {
      this.token = token;
      this.test_connection().subscribe(
        (_) => {
          this.isAuthenticated.next(true);
        },
        (_) => {
          this.isAuthenticated.next(false);
        },
        () => {
          console.log('Complete');
        }
      );
    } else {
      this.isAuthenticated.next(false);
    }
  }

  register(credentials: {
    email: string;
    password: string;
    phone: string;
    firstName: string;
    lastName: string;
    height: number;
    weight: number;
    address: string;
    waist: number;
    gender: number;
  }): Observable<any> {
    var user = {
      email: credentials.email,
      password: credentials.password,
    };
    var patient = {
      phone: credentials.phone,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      height: credentials.height,
      weight: credentials.weight,
      address: credentials.address,
      waist: credentials.waist,
      gender: credentials.gender,
    };

    var dataRegister = {
      user: user,
      patient: patient,
    };

    console.log(dataRegister);
    return this.http.post(
      `${environment.apiUrl}/patient/register`,
      dataRegister,
      this.httpOptions
    );
  }

  login(credentials: { country_code: string, phone: any; password: any }): Observable<any> {
    if (this.platform.is('hybrid')) {
      return defer(() =>
        from(
          this.native.post(`${environment.apiUrl}/auth/login/`, credentials, {})
        ).pipe(
          tap((data: any) => {
            this.isAuthenticated.next(true);
          })
        )
      );
    } else {
      return this.http
        .post(
          `${environment.apiUrl}/auth/login/`,
          credentials,
          this.httpOptions
        )
        .pipe(
          tap((data) => {
            this.isAuthenticated.next(true);
          })
        );
    }
  }

  async logout(): Promise<[void, void, void, void, Object]> {
    this.isAuthenticated.next(false);
    const refresh_token = await localStorage.getItem('refresh_token');
    let http_logout = this.http
      .post(`${environment.apiUrl}/auth/logout/`, {
        refresh_token: refresh_token,
      })
      .toPromise();
    return Promise.all([
      localStorage.removeItem('refresh_token'),
      localStorage.removeItem('user_id'),
      localStorage.removeItem('role'),
      localStorage.removeItem('token'),
      http_logout,
    ]);
  }

  test_connection() {
    return this.http.get(`${environment.apiUrl}/auth/test/`, this.httpOptions);
  }
}
