import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserAppLoadNavigationService {

  constructor(
    private router: Router
  ) { }

  redirectUserOnAppLoad(pendingCount, userId, userDevices, hasContactList) {
    this.router.navigate([userId + '/user-devices/' + userId]);
  }
}