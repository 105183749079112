import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from './services/language.service';
import { MenuController } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Enregistrer une ceinture', url: '/register-device', icon: 'add-outline' },
    { title: 'Mes ceintures', url: '/devices', icon: 'list-outline' },
    { title: 'Historique', url: '/history', icon: 'time-outline' },
    { title: 'SAV', url: '/sav', icon: 'construct-outline' },
    { title: 'Aide', url: '/help-main', icon: 'information-circle-outline' },
  ];
  
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private menuController: MenuController,
  ) {
    this.translate.setDefaultLang('en');
    this.languageService.getLanguage();
  }
  
  ngOnInit() {
    this.menuController.enable(false);
  }
}
