import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    canLoad: [AutoLoginGuard],
  },
  {
    path: 'change-password/:temporaryPassword',
    loadChildren: () =>
      import('./change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
    canLoad: [AutoLoginGuard],
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./privacy/privacy.module').then((m) => m.PrivacyPageModule),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./terms/terms.module').then((m) => m.TermsPageModule),
  },
  {
    path: ':id/devices',
    loadChildren: () =>
      import('./devices/devices.module').then((m) => m.DevicesPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/contacts-list',
    loadChildren: () =>
      import('./contacts-list/contacts-list.module').then(
        (m) => m.ContactsListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/contact/:cid',
    loadChildren: () =>
      import('./contact-infos/contact-infos.module').then(
        (m) => m.ContactInfosPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/pathologies',
    loadChildren: () =>
      import('./pathologies/pathologies.module').then(
        (m) => m.PathologiesPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/help-main',
    loadChildren: () =>
      import('./help-main/help-main.module').then((m) => m.HelpMainPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/home-bo',
    loadChildren: () =>
      import('./back-office/home-bo/home-bo.module').then(
        (m) => m.HomeBoPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/patients-list',
    loadChildren: () =>
      import('./back-office/patients-list/patients-list.module').then(
        (m) => m.PatientsListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/patient-infos/:pid',
    loadChildren: () =>
      import('./patient-infos/patient-infos.module').then(
        (m) => m.PatientInfosPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/register-patient',
    loadChildren: () =>
      import(
        './back-office/register-patient-bo/register-patient-bo.module'
      ).then((m) => m.RegisterPatientBoPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/admins-list',
    loadChildren: () =>
      import('./back-office/admins-list/admins-list.module').then(
        (m) => m.AdminsListPageModule
      ),
    canLoad: [AuthGuard],
  },
  // {
  //   path: ':id/admin-infos/:aid',
  //   loadChildren: () =>
  //     import('./back-office/admin-infos/admin-infos.module').then(
  //       (m) => m.AdminInfosPageModule
  //     ),
  //   canLoad: [AuthGuard],
  // },
  // {
  //   path: ':id/register-admin',
  //   loadChildren: () =>
  //     import('./back-office/register-admin-bo/register-admin-bo.module').then(
  //       (m) => m.RegisterAdminBoPageModule
  //     ),
  //   canLoad: [AuthGuard],
  // },
  {
    path: ':id/vendors-list',
    loadChildren: () =>
      import('./back-office/vendors-list/vendors-list.module').then(
        (m) => m.VendorsListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/vendor-infos/:vid',
    loadChildren: () =>
      import('./back-office/vendor-infos/vendor-infos.module').then(
        (m) => m.VendorInfosPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/register-vendor',
    loadChildren: () =>
      import('./back-office/register-vendor-bo/register-vendor-bo.module').then(
        (m) => m.RegisterVendorBoPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/users-list',
    loadChildren: () =>
      import('./back-office/users-list/users-list.module').then(
        (m) => m.UsersListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/user-devices/:uid',
    loadChildren: () =>
      import('./user-devices/user-devices.module').then(
        (m) => m.UserDevicesPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/user-profile/:pid',
    loadChildren: () =>
      import('./user-profile/user-profile.module').then(
        (m) => m.UserProfilePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/devices-list',
    loadChildren: () =>
      import('./back-office/devices-list/devices-list.module').then(
        (m) => m.DevicesListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/device-infos/:did',
    loadChildren: () =>
      import('./back-office/device-infos/device-infos.module').then(
        (m) => m.DeviceInfosPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/contact-add',
    loadChildren: () =>
      import('./contact-add/contact-add.module').then(
        (m) => m.ContactAddPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ':id/help-main/use',
    loadChildren: () => import('./help-use/help-use.module').then( m => m.HelpUsePageModule)
  },
  {
    path: 'forgotten-password',
    loadChildren: () => import('./forgotten-password/forgotten-password.module').then( m => m.ForgottenPasswordPageModule)
  },
  {
    path: ':id/prepare-belt',
    loadChildren: () =>
      import(
        './back-office/prepare-belt/prepare-belt.module'
      ).then((m) => m.PrepareBeltPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'activate/:activationCode',
    loadChildren: () =>
      import(
        './back-office/register-patient-bo/register-patient-bo.module'
      ).then((m) => m.RegisterPatientBoPageModule),
  },
  // {
  //   path: ':id/new-phone',
  //   loadChildren: () => import('./new-phone/new-phone.module').then( m => m.NewPhonePageModule),
  //   canLoad: [AuthGuard],
  // },
  {
    path: ':id/change-phone',
    loadChildren: () => import('./change-phone/change-phone.module').then( m => m.ChangePhonePageModule),
    canLoad: [AuthGuard],
  },
  {path: '**', redirectTo: 'login', pathMatch: 'full',},
  // {
  //   path: ':id/register-contact',
  //   loadChildren: () =>
  //     import('./register-contact/register-contact.module').then(
  //       (m) => m.RegisterContactPageModule
  //     ),
  // },
  // {
  //   path: ':id/favorite-numbers',
  //   loadChildren: () =>
  //     import('./favorite-numbers/favorite-numbers.module').then(
  //       (m) => m.FavoriteNumbersPageModule
  //     ),
  // },
  // {
  //   path: 'account',
  //   loadChildren: () =>
  //     import('./account/account.module').then((m) => m.AccountPageModule),
  // },
  // {
  //   path: 'sav',
  //   loadChildren: () => import('./sav/sav.module').then((m) => m.SavPageModule),
  // },
  // {
  //   path: 'device',
  //   loadChildren: () =>
  //     import('./device/device.module').then((m) => m.DevicePageModule),
  // },
  // {
  //   path: 'register-device',
  //   loadChildren: () =>
  //     import('./register-device/register-device.module').then((m) => m.RegisterDevicePageModule),
  // },
  // {
  //   path: 'register-user',
  //   loadChildren: () =>
  //     import('./register-user/register-user.module').then(
  //       (m) => m.RegisterUserPageModule
  //     ),
  // },
  // {
  //   path: 'statistics-device',
  //   loadChildren: () =>
  //     import('./statistics-device/statistics-device.module').then(
  //       (m) => m.StatisticsDevicePageModule
  //     ),
  // },
  // {
  //   path: 'statistics-user',
  //   loadChildren: () =>
  //     import('./statistics-user/statistics-user.module').then(
  //       (m) => m.StatisticsUserPageModule
  //     ),
  // },
  // {
  //   path: 'bank-infos',
  //   loadChildren: () =>
  //     import('./bank-infos/bank-infos.module').then(
  //       (m) => m.BankInfosPageModule
  //     ),
  // },
  // {
  //   path: 'about',
  //   loadChildren: () =>
  //     import('./about/about.module').then((m) => m.AboutPageModule),
  // },
  // {
  //   path: 'register',
  //   loadChildren: () =>
  //     import('./register/register.module').then((m) => m.RegisterPageModule),
  // },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () =>
  //     import('./folder/folder.module').then((m) => m.FolderPageModule),
  // },
  // {
  //   path: 'history',
  //   loadChildren: () =>
  //     import('./history/history.module').then((m) => m.HistoryPageModule),
  // },
  // {
  //   path: 'profile',
  //   loadChildren: () =>
  //     import('./profile/profile.module').then((m) => m.ProfilePageModule),
  // },
  // {
  //   path: 'cart',
  //   loadChildren: () =>
  //     import('./cart/cart.module').then((m) => m.CartPageModule),
  // },
  // {
  //   path: 'devices',
  //   loadChildren: () => import('./devices/devices.module').then( m => m.DevicesPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
